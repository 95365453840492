import isEqual from 'lodash.isequal';

const isObject = object => {
  return object && typeof object === 'object' && object.constructor === Object;
};

const isObjectWithValues = object =>
  !!(isObject(object) && Object.keys(object).length > 0);

const isObjectWithKeyAndNotNullUndefinedNanFalse0VoidString = (
  object,
  keyToSearch
) => {
  return isObjectWithValues(object) && !!object[keyToSearch];
};

const objectHasKey = (object, key) => {
  if (!isObjectWithValues(object)) {
    return false;
  }
  if (!(key in object)) {
    return false;
  }
  if (object[key] === undefined) {
    return false;
  }
  return true;
};

const objectHasKeys = (object, keys) =>
  keys.every(key => objectHasKey(object, key));

const isEmptyObject = object => !isObjectWithValues(object);

const isObjectWithIncludeKey = (object, keyToSearch) => {
  const keys = Object.keys(object).map(key => key.toLowerCase());
  const subKey = keyToSearch.toLowerCase();
  return isObjectWithValues(object) && !!keys.find(key => key.includes(subKey));
};

const isObjectWithKeyAndValue = (object, keyToSearch, valueToValidate) => {
  return (
    isObjectWithKeyAndNotNullUndefinedNanFalse0VoidString(
      object,
      keyToSearch
    ) && object[keyToSearch] === valueToValidate
  );
};

const isEqualObject = (object1, object2) => {
  return isEqual(object1, object2);
};

const isValidJSONString = json => {
  try {
    JSON.parse(json);
  } catch (error) {
    return false;
  }
  return true;
};

export {
  isObject,
  isObjectWithValues,
  isEqualObject,
  isObjectWithKeyAndNotNullUndefinedNanFalse0VoidString,
  isObjectWithKeyAndValue,
  isObjectWithIncludeKey,
  isEmptyObject,
  objectHasKey,
  objectHasKeys,
  isValidJSONString
};
