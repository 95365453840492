import { useMemo } from 'react';
import PropTypes from 'prop-types';

const Arrivals = ({ color, size }) => {
  const iconStyles = useMemo(() => {
    if (!color) {
      return null;
    }
    return { fill: color };
  }, [color]);
  return (
    <svg
      className={`icon arrivals-icon ${size}`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={iconStyles}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.18 14.79C20.96 15.59 20.14 16.06 19.34 15.85L2.77 11.41V6.24L4.22 6.63L5.15 8.95L10.12 10.28V2L12.05 2.51L14.81 11.53L20.12 12.95C20.92 13.17 21.39 13.99 21.18 14.79ZM21.5 19H2.5V21H21.5V19Z"
      />
    </svg>
  );
};
Arrivals.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string
};
Arrivals.defaultProps = {
  color: undefined,
  size: ''
};

export default Arrivals;
