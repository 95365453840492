export const host = `${process.env.NEXT_PUBLIC_BACKEND_HOST}/api`;

export const headers =
  process.env.NODE_ENV === 'development'
    ? {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${process.env.NEXT_PUBLIC_BACKEND_TOKEN}`
        // 'X-NP-Version': `${pwaVersion}`
      }
    : {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        credentials: 'include'
        // 'X-NP-Version': `${pwaVersion}`
      };

export const establishments = '/establishments';
export const establishmentsById = '/establishments/$id';
export const establishmentTypes = '/establishment/types';

export const sellers = '/sellers';
export const sellersById = '/sellers/$id';

export const currentUser = '/currentUser';
export const users = '/users';
export const usersById = '/users/$id';
export const usersChangePasswordById = '/userPassword/$id';

export const createAccount = '/createAccount';

export const login = '/login';
export const forgotPassword = '/auth/forgotPassword';
export const resetPassword = '/auth/resetPassword';
export const logout = '/logout';

export const mappingAgencies = '/mapping/agencies';
export const mappingAgenciesById = '/mapping/agencies/$id';
export const agencies = '/agencies';

export const mappingPensions = '/mapping/pensions';
export const mappingPensionsById = '/mapping/pensions/$id';
export const pensions = '/pensions';

export const mappingProducts = '/mapping/products';
export const mappingProductsById = '/mapping/products/$id';
export const products = '/products';

export const mappingRooms = '/mapping/rooms';
export const mappingRoomsById = '/mapping/rooms/$id';
export const rooms = '/rooms';

export const mappingSeasons = '/mapping/seasons';
export const mappingSeasonsById = '/mapping/seasons/$id';
export const seasons = '/seasons';

export const countries = '/countries';

export const arrivals = '/bookings';
export const arrivalById = '/bookings/$id';
export const processScoring = '/bookings/processScoring';
export const bookingsPendingByEstablishmentId =
  '/bookings/pending/$establishmentId';

export const trackings = '/bookingClassifications';
export const trackingById = '/bookingClassifications/$id';

export const companiesById = '/companies/$id';

export const stripe = '/stripe';
export const stripeProducts = '/stripe/products';
export const reporting = '/reporting';

export const notifications = '/notifications';

export const cancelSubscription = '/stripe/cancel';
export const stripePaymentIntent = '/stripe/paymentIntent';

export const pmss = '/pmss';
