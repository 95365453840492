import { formatDate } from '../../../../../../../utils/functions/formatter/dateFormaterMoment';
import {
  formatCurrency,
  numberFormatter
} from '../../../../../../../utils/functions/formatter/numberFormatter';
import { isArrayWithItems } from '../../../../../../../utils/functions/validator/arrayValidator';

const obtainInputValue = ({ value, inputType, options }) => {
  switch (inputType) {
    case 'select': {
      if (!isArrayWithItems(options)) {
        return value;
      }
      const optionSelected = options.find(option => option.value === value);
      return optionSelected?.children || optionSelected?.label;
    }
    case 'country': {
      if (!isArrayWithItems(options)) {
        return value;
      }
      const country = options.find(option => option.value === value);
      return country?.option?.iso2 || country?.label;
    }
    case 'datepicker':
      return formatDate(value, 'DD-MM-YYYY');
    case 'password':
      return value.replace(value, '********');
    case 'currency':
      return formatCurrency(value);
    case 'float':
    case 'int':
      return numberFormatter({ number: value });

    default:
      return value;
  }
};

export default obtainInputValue;
