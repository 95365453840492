import { useMemo } from 'react';
import PropTypes from 'prop-types';

const Delete = ({ color, size, className }) => {
  const iconStyles = useMemo(() => {
    if (!color) {
      return null;
    }
    return { fill: color };
  }, [color]);
  return (
    <svg
      className={`icon delete-icon ${size} ${className}`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={iconStyles}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
    </svg>
  );
};
Delete.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string
};
Delete.defaultProps = {
  color: undefined,
  size: '',
  className: ''
};

export default Delete;
