import isEqual from 'lodash.isequal';
import { isNumber } from './numberValidator';
import { isObjectWithKeyAndValue, isObjectWithValues } from './objectValidator';
import { isStringWithCharacters } from './stringValidator';

const isArray = array => Array.isArray(array);

const isArrayWithItems = array => Array.isArray(array) && array.length > 0;

const isArrayWithItemsUndefined = array =>
  isArrayWithItems(array) && array.every(value => value === undefined);

const isArrayWithStringItems = array =>
  isArrayWithItems(array) &&
  array.every(value => isStringWithCharacters(value));

const isArrayWithNumberItems = array =>
  isArrayWithItems(array) && array.every(value => isNumber(value));

const isEqualArray = (array1, array2) => {
  return isEqual(array1, array2);
};

const isEmptyArray = array => isArray(array) && array.length === 0;

const isArrayWithXItems = (array, numberOfItems) =>
  isArrayWithItems(array) && array.length > numberOfItems;

const isArrayWithSpecificObject = (array, key, value) =>
  isArrayWithItems(array) &&
  array.some(element => isObjectWithKeyAndValue(element, key, value));

const isArrayWithObjects = array =>
  isArrayWithItems(array) && array.every(value => isObjectWithValues(value));

export {
  isArray,
  isArrayWithItems,
  isEqualArray,
  isArrayWithItemsUndefined,
  isEmptyArray,
  isArrayWithStringItems,
  isArrayWithNumberItems,
  isArrayWithXItems,
  isArrayWithSpecificObject,
  isArrayWithObjects
};
