import PropTypes from 'prop-types';
import { Card as CardAntd } from 'antd';

const Card = ({
  title,
  children,
  style,
  bodyStyle,
  headStyle,
  loading,
  className,
  actions,
  extra,
  bordered,
  showHeadBorderBottom
}) => {
  return (
    <CardAntd
      title={title}
      className={`card ${className} ${
        showHeadBorderBottom ? '' : 'hide-head-border-bottom'
      }`}
      style={style}
      bodyStyle={bodyStyle}
      headStyle={headStyle}
      loading={loading}
      actions={actions}
      extra={extra}
      bordered={bordered}
    >
      {children}
    </CardAntd>
  );
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  extra: PropTypes.node,
  style: PropTypes.objectOf(Object),
  headStyle: PropTypes.objectOf(Object),
  bodyStyle: PropTypes.objectOf(Object),
  className: PropTypes.string,
  bordered: PropTypes.bool,
  actions: PropTypes.node,
  showHeadBorderBottom: PropTypes.bool
};
Card.defaultProps = {
  loading: false,
  title: undefined,
  extra: undefined,
  style: undefined,
  headStyle: undefined,
  bodyStyle: undefined,
  className: '',
  bordered: true,
  actions: undefined,
  showHeadBorderBottom: false
};

export default Card;
