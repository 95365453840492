import isEqual from 'lodash.isequal';
import { isUuidRegex } from './regularExpressions';

const isString = string => typeof string === 'string';

const isStringWithCharacters = (string, minCharacter = 0) =>
  isString(string) && string.length > minCharacter;

const isEmptyString = (string, minCharacter) =>
  !isStringWithCharacters(string, minCharacter);

const isUuid = string => isString(string) && string.match(isUuidRegex);

const isEqualString = (string1, string2) => isEqual(string1, string2);

export {
  isString,
  isStringWithCharacters,
  isUuid,
  isEmptyString,
  isEqualString
};
