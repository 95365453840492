import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../info/Tooltip/Tooltip';
import useTranslation from '../../../../i18n/hooks/useTranslation';
import { getWidthTextElement } from '../../../../utils/functions/formatter/stringFormatter';
import { isStringWithCharacters } from '../../../../utils/functions/validator/stringValidator';

const TextEllipsable = props => {
  const {
    text,
    className,
    tooltipTitle,
    tooltipType,
    showTooltip,
    tooltipPlacement,
    color,
    colon
  } = props;

  const parentRef = useRef();
  const { translation } = useTranslation();

  let contentWidth = null;
  const [isContentOverflowed, setIsContentOverflowed] = useState(undefined);

  const getResizeObserver = () => {
    if (typeof ResizeObserver === 'function') {
      return new ResizeObserver(entry => {
        // We wrap it in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entry) || !entry.length) {
            return;
          }

          const element = entry[0].target;

          contentWidth = getWidthTextElement(element);
          setIsContentOverflowed(element.offsetWidth <= contentWidth);
        });
      });
    }

    return false;
  };

  const resizeObserver = getResizeObserver();

  useEffect(() => {
    let tagContentElement;
    if (parentRef) {
      tagContentElement = parentRef.current;
      if (resizeObserver && tagContentElement) {
        resizeObserver.observe(tagContentElement);
      }
    }

    return () => {
      if (resizeObserver && tagContentElement) {
        resizeObserver.unobserve(tagContentElement);
      }
    };
  }, [isContentOverflowed, resizeObserver, parentRef]);
  const textDisplay = useMemo(
    () => (isStringWithCharacters(text) ? translation(text) : text),
    [text, translation]
  );
  return (
    <div className="text-ellipsable" ref={parentRef}>
      <div
        className={`text-ellipsable__text ${className || ''} ${
          colon ? 'with-colon' : ''
        }`}
      >
        {showTooltip || isContentOverflowed ? (
          <Tooltip
            title={tooltipTitle || text}
            type={tooltipType}
            placement={tooltipPlacement}
            color={color}
          >
            <span className={!showTooltip && 'text-overflowed'}>
              {textDisplay}
            </span>
          </Tooltip>
        ) : (
          textDisplay
        )}
      </div>
    </div>
  );
};

TextEllipsable.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  tooltipTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  tooltipType: PropTypes.string,
  tooltipPlacement: PropTypes.string,
  color: PropTypes.string,
  colon: PropTypes.bool,
  showTooltip: PropTypes.bool
};

TextEllipsable.defaultProps = {
  text: undefined,
  className: undefined,
  tooltipTitle: undefined,
  tooltipType: 'default',
  tooltipPlacement: 'top',
  color: undefined,
  colon: false,
  showTooltip: undefined
};

export default TextEllipsable;
