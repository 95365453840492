import { useMemo } from 'react';
import PropTypes from 'prop-types';

const Menu = ({ color, size }) => {
  const iconStyles = useMemo(() => {
    if (!color) {
      return null;
    }
    return { fill: color };
  }, [color]);
  return (
    <svg
      className={`icon menu-icon ${size}`}
      style={iconStyles}
      height="24px"
      width="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
    </svg>
  );
};
Menu.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string
};
Menu.defaultProps = {
  color: undefined,
  size: ''
};

export default Menu;
