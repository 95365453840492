import PropTypes from 'prop-types';
import TextEllipsable from '../../typo/textEllipsable/TextEllipsable';

const NPInputLabel = ({
  ellipsis,
  label,
  size,
  children,
  labelPosition,
  tooltipTitle,
  showTooltip,
  tooltipType,
  boldLabel,
  labelTextTransform
}) => {
  return (
    <div className="input-label-container">
      <div className="input-label-container-item">
        <div
          className={`input-label ${size || ''} ${labelPosition || ''} ${
            boldLabel ? 'bold-label' : ''
          } ${labelTextTransform}`}
        >
          {showTooltip || ellipsis ? (
            <TextEllipsable
              text={label}
              tooltipType={tooltipType}
              tooltipTitle={tooltipTitle}
              showTooltip={showTooltip}
              colon
            />
          ) : (
            <span className="input-label-text">{label}:</span>
          )}
        </div>
      </div>
      <div className="input-label-container-item">{children}</div>
    </div>
  );
};

NPInputLabel.propTypes = {
  ellipsis: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node
  ]).isRequired,
  size: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node
  ]).isRequired,
  labelPosition: PropTypes.string,
  layout: PropTypes.objectOf(Object),
  tooltipTitle: PropTypes.string,
  boldLabel: PropTypes.bool,
  showTooltip: PropTypes.bool,
  tooltipType: PropTypes.string,
  labelTextTransform: PropTypes.string
};
NPInputLabel.defaultProps = {
  ellipsis: false,
  size: undefined,
  labelPosition: 'top',
  layout: undefined,
  tooltipTitle: undefined,
  boldLabel: true,
  showTooltip: undefined,
  tooltipType: 'neutral',
  labelTextTransform: 'first-letter'
};

export default NPInputLabel;
