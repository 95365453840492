import { useMemo } from 'react';
import PropTypes from 'prop-types';

const Add = ({ color, size }) => {
  const iconStyles = useMemo(() => {
    if (!color) {
      return null;
    }
    return { fill: color };
  }, [color]);
  return (
    <svg
      className={`icon add-icon ${size}`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={iconStyles}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
    </svg>
  );
};
Add.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string
};
Add.defaultProps = {
  color: undefined,
  size: ''
};

export default Add;
