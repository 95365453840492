import { useMemo } from 'react';
import PropTypes from 'prop-types';

const Warning = ({ color, size }) => {
  const iconStyles = useMemo(() => {
    if (!color) {
      return null;
    }
    return { fill: color };
  }, [color]);
  return (
    <svg
      className={`icon warning-icon ${size}`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={iconStyles}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
    </svg>
  );
};
Warning.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string
};
Warning.defaultProps = {
  color: undefined,
  size: ''
};

export default Warning;
