import { useMemo } from 'react';
import PropTypes from 'prop-types';

const Mappings = ({ color, size }) => {
  const iconStyles = useMemo(() => {
    if (!color) {
      return null;
    }
    return { fill: color };
  }, [color]);
  return (
    <svg
      className={`icon mappings-icon ${size}`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={iconStyles}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 10.9C11.39 10.9 10.9 11.39 10.9 12C10.9 12.61 11.39 13.1 12 13.1C12.61 13.1 13.1 12.61 13.1 12C13.1 11.39 12.61 10.9 12 10.9ZM6 18L14.19 14.19L18 6L9.81 9.81L6 18Z"
      />
    </svg>
  );
};
Mappings.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string
};
Mappings.defaultProps = {
  color: undefined,
  size: ''
};

export default Mappings;
