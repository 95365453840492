import { useMemo } from 'react';
import PropTypes from 'prop-types';

const Reporting = ({ color, size }) => {
  const iconStyles = useMemo(() => {
    if (!color) {
      return null;
    }
    return { fill: color };
  }, [color]);
  return (
    <svg
      className={`icon reporting-icon ${size}`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={iconStyles}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3H19C20.1 3 21 3.9 21 5V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3ZM7 17H9V10H7V17ZM13 17H11V7H13V17ZM15 17H17V13H15V17Z"
      />
    </svg>
  );
};
Reporting.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string
};
Reporting.defaultProps = {
  color: undefined,
  size: ''
};

export default Reporting;
