import React from 'react';
import PropTypes from 'prop-types';
import checkIsOnClickTriggerAction from './utils/functions/checkIsOnClickTriggerAction';
import getActionTriggersClassNames from './utils/functions/getActionTriggersClassNames';
import { Edit } from '../../../assets/icons';

const InputActions = props => {
  const { canEdit, onClickEdit, className } = props;

  return (
    <div className={`input-actions ${className || ''}`}>
      {canEdit && (
        <div
          role="presentation"
          onClick={onClickEdit}
          className="input-actions__edit"
        >
          <Edit className="input-actions__edit-icon" />
        </div>
      )}
    </div>
  );
};

InputActions.propTypes = {
  canEdit: PropTypes.bool,
  onClickEdit: PropTypes.func,
  className: PropTypes.string
};
InputActions.defaultProps = {
  canEdit: false,
  onClickEdit: undefined,
  className: undefined
};

export {
  InputActions as default,
  InputActions,
  checkIsOnClickTriggerAction,
  getActionTriggersClassNames
};
