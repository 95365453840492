const isLetterRegex = /^[A-Za-z]+$/;

const isNumberRegex = /[0-9]/;

const isUuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;

const isRegexMatch = (regex, valueToMatch) => {
  return valueToMatch.match(regex);
};

export { isLetterRegex, isNumberRegex, isUuidRegex, isRegexMatch };
