import { useMemo } from 'react';
import PropTypes from 'prop-types';

const Close = ({ color, size }) => {
  const iconStyles = useMemo(() => {
    if (!color) {
      return null;
    }
    return { fill: color };
  }, [color]);
  return (
    <svg
      className={`icon close-icon ${size}`}
      style={iconStyles}
      height="24px"
      width="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
    </svg>
  );
};
Close.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string
};
Close.defaultProps = {
  color: undefined,
  size: ''
};

export default Close;
