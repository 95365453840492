import { useMemo } from 'react';
import PropTypes from 'prop-types';

const ArrowDropDown = ({ color, size }) => {
  const iconStyles = useMemo(() => {
    if (!color) {
      return null;
    }
    return { fill: color };
  }, [color]);
  return (
    <svg
      className={`icon arrow-drop-down-icon ${size}`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={iconStyles}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M7 10l5 5 5-5H7z" />
    </svg>
  );
};
ArrowDropDown.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string
};
ArrowDropDown.defaultProps = {
  color: undefined,
  size: ''
};

export default ArrowDropDown;
