import { isArrayWithItems } from '../../../../../../../utils/functions/validator/arrayValidator';

const checkIsOnClickTriggerAction = triggers => {
  let isOnClickAction = false;
  if (isArrayWithItems(triggers)) {
    isOnClickAction = triggers.some(trigger => trigger === 'onClick');
  }
  return isOnClickAction;
};

export default checkIsOnClickTriggerAction;
