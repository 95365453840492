import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  InputActions,
  checkIsOnClickTriggerAction,
  getActionTriggersClassNames
} from '../inputActions/InputActions';
import InputEmptyValue from './components/InputEmptyValue';
import obtainInputValue from './utils/functions/obtainInputValue';
import { isNumber } from '../../../../../utils/functions/validator/numberValidator';
import { isMoment } from '../../../../../utils/functions/validator/dateValidator';
import { isString } from '../../../../../utils/functions/validator/stringValidator';

const InputViewMode = props => {
  const {
    suffix,
    value,
    labelText,
    canEdit,
    onClickEdit,
    size,
    editTrigger,
    prefix,
    options,
    inputType,
    hideCanEditIcon,
    fullWidth,
    precision
  } = props;
  const [inputActionsClasses, setInputActionsClasses] = useState(undefined);
  const [isEditTriggerOnClick, setIsEditTriggerOnClick] = useState(false);
  useEffect(() => {
    const checkEditTriggers = () => {
      if (canEdit) {
        const actionsClassNames = getActionTriggersClassNames(editTrigger);
        const isOnClickTriggerAction = checkIsOnClickTriggerAction(editTrigger);

        setInputActionsClasses(actionsClassNames);
        setIsEditTriggerOnClick(isOnClickTriggerAction);
      } else {
        setInputActionsClasses(undefined);
        setIsEditTriggerOnClick(false);
      }
    };
    checkEditTriggers();
  }, [editTrigger, canEdit]);

  const hasValue = isString(value) || isNumber(value) || isMoment(value);

  return (
    <div
      className={`input-view-mode ${size || ''} ${inputActionsClasses || ''} ${
        fullWidth ? 'full-width' : ''
      }`}
    >
      <div className="input-info">
        {labelText && (
          <span className="input-info__label">{`${labelText}: `}</span>
        )}
        <span
          className="input-info__value"
          onClick={isEditTriggerOnClick ? onClickEdit : undefined}
          role="presentation"
        >
          {prefix && hasValue && (
            <span className="input-info__value-prefix">{prefix}</span>
          )}
          {hasValue ? (
            obtainInputValue({ value, inputType, options, precision })
          ) : (
            <InputEmptyValue inputType={inputType} />
          )}
          {suffix && hasValue && (
            <span className="input-info__value-suffix">{suffix}</span>
          )}
        </span>
      </div>
      {hideCanEditIcon ||
        (canEdit && (
          <InputActions canEdit={canEdit} onClickEdit={onClickEdit} />
        ))}
    </div>
  );
};

InputViewMode.propTypes = {
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.any]),
  labelText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  canEdit: PropTypes.bool,
  onClickEdit: PropTypes.func,
  size: PropTypes.string,
  editTrigger: PropTypes.arrayOf(String),
  options: PropTypes.arrayOf(Array),
  inputType: PropTypes.string,
  hideCanEditIcon: PropTypes.bool,
  fullWidth: PropTypes.bool,
  precision: PropTypes.number
};

InputViewMode.defaultProps = {
  suffix: undefined,
  prefix: undefined,
  value: undefined,
  labelText: undefined,
  canEdit: true,
  onClickEdit: undefined,
  size: undefined,
  editTrigger: ['fixed', 'onClick'],
  options: undefined,
  inputType: undefined,
  hideCanEditIcon: undefined,
  fullWidth: false,
  precision: undefined
};

export { InputViewMode as default, InputViewMode };
