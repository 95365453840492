import { isArrayWithItems } from '../../../../../../../utils/functions/validator/arrayValidator';

const getActionTriggersClassNames = triggers => {
  let newInputActionsClassNames = '';
  if (isArrayWithItems(triggers)) {
    triggers.forEach(trigger => {
      switch (trigger) {
        case 'onHover': {
          newInputActionsClassNames = newInputActionsClassNames.concat(
            'on-hover-action-trigger '
          );
          break;
        }
        case 'onClick': {
          newInputActionsClassNames = newInputActionsClassNames.concat(
            'on-click-action-trigger '
          );
          break;
        }
        case 'fixed': {
          newInputActionsClassNames = newInputActionsClassNames.concat(
            `${trigger}-action-trigger `
          );
          break;
        }
        default:
          break;
      }
    });
  }
  return newInputActionsClassNames;
};

export default getActionTriggersClassNames;
