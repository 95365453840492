import { useMemo } from 'react';
import PropTypes from 'prop-types';

const Info = ({ color, size, onClick }) => {
  const iconStyles = useMemo(() => {
    if (!color) {
      return null;
    }
    return { fill: color };
  }, [color]);
  return (
    <svg
      className={`icon info-icon ${size}`}
      onClick={onClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={iconStyles}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM11 17V11H13V17H11ZM11 7V9H13V7H11Z"
      />
    </svg>
  );
};
Info.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func
};
Info.defaultProps = {
  color: undefined,
  size: '',
  onClick: undefined
};

export default Info;
