import { useMemo } from 'react';
import PropTypes from 'prop-types';

const Trackings = ({ color, size }) => {
  const iconStyles = useMemo(() => {
    if (!color) {
      return null;
    }
    return { fill: color };
  }, [color]);
  return (
    <svg
      className={`icon trackings-icon ${size}`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={iconStyles}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18 4H16V11.9L18 9.9V4Z" />
      <rect x="4" y="4" width="2" height="16" />
      <rect x="10" y="4" width="2" height="4" />
      <rect x="10" y="10" width="2" height="4" />
      <rect x="10" y="16" width="2" height="4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.41 11.44L22.56 12.59C23.15 13.17 23.15 14.12 22.56 14.71L17.27 20H14V16.73L19.29 11.44C19.87 10.85 20.82 10.85 21.41 11.44ZM15.55 18.45H16.58L20.03 15L19 13.97L15.55 17.42V18.45Z"
      />
    </svg>
  );
};
Trackings.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string
};
Trackings.defaultProps = {
  color: undefined,
  size: ''
};

export default Trackings;
