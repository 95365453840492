import PropTypes from 'prop-types';
import { Tooltip as TooltipAntd } from 'antd';
import { isStringWithCharacters } from '../../../../utils/functions/validator/stringValidator';
import useTranslation from '../../../../i18n/hooks/useTranslation';

const Tooltip = props => {
  const {
    title,
    children,
    placement,
    type,
    padding,
    color,
    uppercase,
    className,
    translationExtraProps
  } = props;

  const { translation } = useTranslation();
  const titleDisplay = isStringWithCharacters(title)
    ? translation(title, translationExtraProps)
    : title;

  return title ? (
    <TooltipAntd
      title={titleDisplay}
      placement={placement}
      overlayClassName={`tooltip ${type} ${!padding && 'no-padding'} ${
        uppercase && 'uppercase'
      } ${className || ''}`}
      color={color}
    >
      <span className="tooltip__span">{children}</span>
    </TooltipAntd>
  ) : (
    children
  );
};

Tooltip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node.isRequired,
  placement: PropTypes.string,
  type: PropTypes.string,
  padding: PropTypes.bool,
  color: PropTypes.string,
  uppercase: PropTypes.bool,
  className: PropTypes.string,
  translationExtraProps: PropTypes.objectOf(Object)
};

Tooltip.defaultProps = {
  title: null,
  placement: 'top',
  type: 'default',
  padding: true,
  color: undefined,
  uppercase: false,
  className: undefined,
  translationExtraProps: undefined
};

export { Tooltip as default };
