import { isString } from '../validator/stringValidator';

const locale = 'es-ES';
const defaultCurrency = 'EUR';

const formatCurrency = (number, currency = defaultCurrency) => {
  const parseNumber = parseFloat(number, 10);
  const numberToFormat = Number.isNaN(parseNumber) ? 0 : parseNumber;
  return numberToFormat.toLocaleString(locale, { style: 'currency', currency });
};

const maximumDecimals = (number = 0, max = 2) => {
  return parseFloat(number.toFixed(max));
};

const isNumber = value => {
  return typeof value === 'number';
};

const toInt = (string, radix = 10) => parseInt(string, radix);

const toFixed = (value, fractionDigits = 1) => {
  if (isNumber(value)) {
    return value.toFixed(fractionDigits);
  }
  if (isString(value)) {
    const float = parseFloat(value);
    return float.toFixed(fractionDigits);
  }

  return value;
};

const formatNumberByLanguage = number => {
  return Intl.NumberFormat().format(number);
};

const parseToFloatOrZero = number => {
  const parsedNumber = parseFloat(number);
  return Number.isNaN(parsedNumber) ? 0 : parsedNumber;
};

const numberFormatter = ({
  number,
  customLocale = 'de-DE',
  maxDecimals = 2,
  minDecimals = 2
}) => {
  if (!isNumber(number)) {
    return 0;
  }

  return number.toLocaleString(customLocale, {
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals
  });
};

export {
  maximumDecimals,
  formatCurrency,
  isNumber,
  toInt,
  toFixed,
  formatNumberByLanguage,
  parseToFloatOrZero,
  numberFormatter
};
