import moment from 'moment';
import 'moment/locale/es';

const incomingTypesFormats = {
  date: 'DD/MM/YYYY',
  time: 'HH:mm',
  dateTime: 'DD/MM/YYYY HH:mm',
  dateMonth: 'MMMM'
};

const incomingFormats = [
  'MM',
  'e',
  'X',
  'DD/MM/YYYY',
  'YYYY-MM-DD',
  'YYYY-MM',
  'YYYY-MM-DD HH:mm',
  'YYYY-MM-DD HH:mm:ss',
  'HH:mm',
  'HH:mm:ss',
  'DD-MM'
];

const addInTheDate = (date, count, type) => {
  return moment(date, incomingFormats).add(count, type);
};

const subtractInTheDate = (date, count, type) => {
  return moment(date, incomingFormats).subtract(count, type);
};

const durationBetweenTwoDates = (date1, date2, format) => {
  let duration = moment.duration(
    moment(date1, incomingFormats).diff(moment(date2, incomingFormats))
  );
  if (typeof format === 'string') duration = duration.as(format);
  return duration;
};

const getDurationByDate = (number, numberType, formatType) => {
  const durationByDate = moment.duration(number, numberType);
  return formatType ? durationByDate.as(formatType) : durationByDate;
};

const getTotalCountTimeByDate = date => {
  const duration = durationBetweenTwoDates(new Date(), date);
  const totalCount = moment('00:00', incomingFormats).add(duration, 's');
  return totalCount;
};

const getDayOfWeek = date => {
  return moment(date, incomingFormats).isoWeekday();
};

const isWeekend = date => {
  const dateWeek = moment(date, incomingFormats).isoWeekday();
  return !!(dateWeek === 6 || dateWeek === 7);
};

const getTotalDaysOfYear = () => {
  const totalDaysOfYear = moment(new Date()).isLeapYear() ? 366 : 365;
  return totalDaysOfYear;
};

const formatDate = (date, format) => {
  return moment(date, incomingFormats).format(format);
};

const formatDateByType = (date, type) => {
  return moment(date, incomingFormats).format(incomingTypesFormats[type]);
};

const formatDateFromNow = date => {
  return moment(date, incomingFormats).fromNow();
};

const formatDateFrom = (dateTo, dateFrom = new Date()) => {
  return moment(dateFrom, incomingFormats).from(
    moment(dateTo, incomingFormats)
  );
};

const formatDateFromNowByDay = date => {
  const auxDateToday = moment().format('DD');
  const auxDateTomorrow = moment().add(1, 'd').format('DD');
  const auxDateYesterday = moment().subtract(1, 'd').format('DD');
  const incomingDate = moment(date, incomingFormats).format('DD');
  let dateFormated;
  if (
    incomingDate === auxDateToday ||
    incomingDate === auxDateTomorrow ||
    incomingDate === auxDateYesterday
  ) {
    [dateFormated] = moment(date, incomingFormats).calendar().split(' ');
  } else {
    dateFormated = moment(date, incomingFormats).format('dddd');
  }
  return dateFormated;
};

const formatDateToNow = date => {
  return moment().calendar(date, 'HH:mm');
};

const getStringDateInMoment = date => {
  return date ? moment(date, incomingFormats) : null;
};

const isSameDate = (date1, date2, typeDateToCheck = 'day') => {
  return moment(date1, incomingFormats).isSame(
    moment(date2, incomingFormats),
    typeDateToCheck
  );
};

const obtainFirstDayOfWeek = date => {
  const dateToObtainFirstDay = date ? moment(date, incomingFormats) : moment();
  return dateToObtainFirstDay.isoWeekday(1);
};

const obtainLastDayOfWeek = date => {
  const dateToObtainLastDay = date ? moment(date, incomingFormats) : moment();
  return dateToObtainLastDay.isoWeekday(7);
};

const obtainFirstDayOfWeekInTimestamp = date => {
  return obtainFirstDayOfWeek(date).unix();
};

const obtainFirstDayOfWeekInTimestampOnZeroHours = date => {
  return obtainFirstDayOfWeek(date).hour(0).minute(0).second(0).unix();
};

const obtainLastDayOfWeekInTimestamp = date => {
  return obtainLastDayOfWeek(date).unix();
};

const obtainLastDayOfWeekInTimestampOn2359 = date => {
  return obtainLastDayOfWeek(date).hour(23).minute(59).second(59).unix();
};

const obtainFirstDayOfMonth = date => {
  const dateToObtainFirstDay = date ? moment(date, incomingFormats) : moment();
  return dateToObtainFirstDay.startOf('month');
};

const obtainLastDayOfMonth = date => {
  const dateToObtainFirstDay = date ? moment(date, incomingFormats) : moment();
  return dateToObtainFirstDay.endOf('month');
};

const obtainFirstDayOfMonthInTimestamp = date => {
  return obtainFirstDayOfMonth(date).unix();
};

const obtainLastDayOfMonthInTimestamp = date => {
  return obtainLastDayOfMonth(date).unix();
};

const obtainFirstDayOfYearInTimestamp = date => {
  return moment(date, incomingFormats).startOf('year').unix();
};

const obtainLastDayOfYearInTimestamp = date => {
  return moment(date, incomingFormats).endOf('year').unix();
};

const formatDateTimestamp = date => {
  return moment(date || new Date(), incomingFormats).unix();
};

const formatDateInTimestampMilliseconds = date => {
  return moment(date, incomingFormats).valueOf();
};

const obtainDateByIsoWeekDay = (isoWeekDay, date, startTime) => {
  const dateByIsoWeekDay = date
    ? moment(date, incomingFormats).isoWeekday(isoWeekDay)
    : moment().isoWeekday(isoWeekDay);
  if (startTime) {
    if (typeof startTime === 'string') {
      const [hours, minutes, seconds] = startTime.split(':');
      dateByIsoWeekDay.hours(hours).minutes(minutes).seconds(seconds);
    } else if (typeof startTime === 'number') {
      const hours = startTime;
      const minutes = 0;
      const seconds = 0;
      dateByIsoWeekDay.hours(hours).minutes(minutes).seconds(seconds);
    }
  }
  return dateByIsoWeekDay;
};

const obtainFormatDateByIsoWeekDay = (isoWeekDay, date, format) => {
  return obtainDateByIsoWeekDay(isoWeekDay, date).format(format);
};

const obtainTimestampByIsoWeekDay = (isoWeekDay, date = moment()) => {
  return moment(date, incomingFormats).isoWeekday(isoWeekDay).unix();
};

const obtainObjectOfMoment = date => {
  return date ? moment(date, incomingFormats) : moment();
};

const obtainTimestampFromFormat = date => {
  return moment(date, incomingFormats).hours(0).minutes(0).unix();
};

const obtainObjectOfNowMoment = () => {
  return moment();
};

const obtainObjectOfMomentAt00h00mFromDate = (date = new Date()) => {
  return moment(date, incomingFormats).hours(0).minutes(0).seconds(0);
};

const obtainObjectOfMomentAt00h00m = () => {
  return moment().hours(0).minutes(0).seconds(0);
};

const obtainObjectOfMomentAt23h59mFromDate = date => {
  return moment(date, incomingFormats).hours(23).minutes(59).seconds(59);
};

const obtainObjectOfMomentAt23h59m = () => {
  return moment().hours(23).minutes(59).seconds(59);
};

const formatDayWithDateFormatted = (timestamp, format = 'DD-MM-YYYY') => {
  return formatDate(timestamp, format);
};

const formatDayWithDateAndTimeFormatted = timestamp => {
  return formatDate(timestamp, 'DD-MM-YYYY HH:mm:ss');
};

const getDayOfWeekTimeStamp = timeStamp => {
  const date = formatDayWithDateAndTimeFormatted(timeStamp);
  return moment(date, incomingFormats).isoWeekday();
};

const secondsToHHMMSS = seconds => {
  const absSeconds = Math.abs(seconds);

  return `${seconds < 0 ? '-' : ''}${Math.trunc(absSeconds / 3600)}h:${`0${
    Math.trunc(absSeconds / 60) % 60
  }`.slice(-2)}m:${`0${absSeconds % 60}`.slice(-2)}s`;
};

const obtainHourOfDate = date => {
  return moment(date, incomingFormats).hour();
};

const obtainRelativePassedTime = (date, format, withFraction) => {
  return moment().diff(moment(date, incomingFormats), format, withFraction);
};

const betweenTwoDays = (initialDate, endDate, date) => {
  return (
    moment(date, incomingFormats).isSameOrAfter(initialDate) &&
    moment(date, incomingFormats).isSameOrBefore(endDate)
  );
};

const formatDateWithLocale = (date, format, locale = 'en') => {
  return moment(date, incomingFormats).locale(locale).format(format);
};

const obtainDateSetIn0HourMinSecInTimestamp = date =>
  obtainObjectOfMomentAt00h00mFromDate(date).unix();

const obtainDateYear = (date = new Date()) => {
  return obtainObjectOfMoment(date).year();
};

const isDateBetweenRange = (
  date,
  dateRange1,
  dateRange2,
  isInclusive = true
) => {
  const momentDate = moment(date, incomingFormats);
  const momentDateRange1 = moment(dateRange1, incomingFormats);
  const momentDateRange2 = moment(dateRange2, incomingFormats);
  return momentDate.isBetween(
    momentDateRange1,
    momentDateRange2,
    undefined,
    isInclusive ? '[]' : '()'
  );
};

const getMonthOfDate = date => moment(date, incomingFormats).month();

const obtainDateByDayOfYear = dayOfYear => moment().date(dayOfYear);

const obtainAllNumberDaysBySameTypeOfIsoWeekDayOfMonth = date => {
  const isoWeekDayOfDate = getDayOfWeek(date);
  const monthOfDate = getMonthOfDate(date);
  let dateToObtainIsoWeekDay = moment()
    .date(1)
    .month(monthOfDate)
    .isoWeekday(isoWeekDayOfDate);
  const days = [];
  let isSearchingIsoWeekDay = true;
  while (isSearchingIsoWeekDay) {
    const day = dateToObtainIsoWeekDay.dayOfYear();
    const isDayOfTheSameMonth =
      getMonthOfDate(dateToObtainIsoWeekDay) === monthOfDate;
    if (isDayOfTheSameMonth) {
      days.push(day);
    }
    dateToObtainIsoWeekDay = dateToObtainIsoWeekDay.add(1, 'w');
    isSearchingIsoWeekDay =
      getMonthOfDate(dateToObtainIsoWeekDay) === monthOfDate;
  }
  return days;
};

const setMomentDateWith2018JanuaryOne = date =>
  moment(date, incomingFormats).year(2018).month(0).date(1);

const convertYearInTimestampsRange = date => {
  if (!date) {
    return null;
  }

  const startDate = obtainFirstDayOfYearInTimestamp(date);
  const endDate = obtainLastDayOfYearInTimestamp(date);

  return {
    startDate,
    endDate
  };
};

export {
  formatDateFromNow,
  formatDate,
  formatDateByType,
  formatDateToNow,
  formatDateFrom,
  formatDateFromNowByDay,
  durationBetweenTwoDates,
  addInTheDate,
  isWeekend,
  subtractInTheDate,
  getDayOfWeek,
  getTotalDaysOfYear,
  getTotalCountTimeByDate,
  getStringDateInMoment,
  isSameDate,
  obtainFirstDayOfWeekInTimestamp,
  obtainLastDayOfWeekInTimestamp,
  obtainFirstDayOfMonthInTimestamp,
  obtainLastDayOfMonthInTimestamp,
  obtainLastDayOfMonth,
  obtainDateByIsoWeekDay,
  formatDateTimestamp,
  formatDateInTimestampMilliseconds,
  obtainFirstDayOfWeekInTimestampOnZeroHours,
  obtainFormatDateByIsoWeekDay,
  obtainTimestampByIsoWeekDay,
  obtainObjectOfMoment,
  obtainTimestampFromFormat,
  obtainLastDayOfWeekInTimestampOn2359,
  obtainObjectOfMomentAt00h00m,
  obtainObjectOfMomentAt23h59m,
  getDurationByDate,
  formatDayWithDateFormatted,
  formatDayWithDateAndTimeFormatted,
  secondsToHHMMSS,
  obtainHourOfDate,
  obtainObjectOfNowMoment,
  getDayOfWeekTimeStamp,
  obtainRelativePassedTime,
  obtainObjectOfMomentAt00h00mFromDate,
  obtainObjectOfMomentAt23h59mFromDate,
  betweenTwoDays,
  formatDateWithLocale,
  obtainDateSetIn0HourMinSecInTimestamp,
  obtainDateYear,
  obtainFirstDayOfYearInTimestamp,
  obtainLastDayOfYearInTimestamp,
  isDateBetweenRange,
  obtainAllNumberDaysBySameTypeOfIsoWeekDayOfMonth,
  obtainDateByDayOfYear,
  setMomentDateWith2018JanuaryOne,
  convertYearInTimestampsRange
};
