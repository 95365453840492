import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const Filter = props => {
  const { size, color, className, onClick, onHoverColor } = props;

  const iconStyles = useMemo(() => {
    if (!color) {
      return null;
    }
    return { fill: color };
  }, [color]);

  return (
    <svg
      className={`icon filter-icon ${size} ${className}`}
      style={iconStyles}
      version="1.1"
      id="filter-icon"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <path
        d="M9.1,11.3c0.3,0.3,0.4,0.6,0.4,1v10.9c0,0.7,0.8,1,1.3,0.5l3-3.5c0.4-0.5,0.6-0.7,0.6-1.2v-6.7c0-0.4,0.1-0.7,0.4-1l8.7-9.5
	C24.2,1.2,23.7,0,22.8,0H1.2c-1,0-1.5,1.1-0.8,1.9L9.1,11.3z"
      />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );
};

Filter.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.string,
  onHoverColor: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string
};

Filter.defaultProps = {
  onClick: undefined,
  size: undefined,
  onHoverColor: undefined,
  color: undefined,
  className: ''
};

export default Filter;
