import moment from 'moment';

const isSameDates = (date1, date2, typeValidate) => {
  return moment(date1).isSame(date2, typeValidate);
};

const isDate = date => {
  return date instanceof Date;
};

const isMoment = date => {
  return !!date && date instanceof moment;
};

// eslint-disable-next-line import/prefer-default-export
export { isSameDates, isDate, isMoment };
