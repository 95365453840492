import { isString } from '../validator/stringValidator';

const upperCase = string => {
  return string.toUpperCase();
};
const lowerCase = string => {
  return string.toLowerCase();
};

const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const replaceAccents = string => {
  let stringFormatted = string;
  if (stringFormatted.search(/[\xC0-\xFF]/g) > -1) {
    stringFormatted = stringFormatted
      .replace(/[\xC0-\xC5]/g, 'A')
      .replace(/[\xC6]/g, 'AE')
      .replace(/[\xC7]/g, 'C')
      .replace(/[\xC8-\xCB]/g, 'E')
      .replace(/[\xCC-\xCF]/g, 'I')
      .replace(/[\xD0]/g, 'D')
      .replace(/[\xD1]/g, 'N')
      .replace(/[\xD2-\xD6\xD8]/g, 'O')
      .replace(/[\xD9-\xDC]/g, 'U')
      .replace(/[\xDD]/g, 'Y')
      .replace(/[\xDE]/g, 'P')
      .replace(/[\xE0-\xE5]/g, 'a')
      .replace(/[\xE6]/g, 'ae')
      .replace(/[\xE7]/g, 'c')
      .replace(/[\xE8-\xEB]/g, 'e')
      .replace(/[\xEC-\xEF]/g, 'i')
      .replace(/[\xF1]/g, 'n')
      .replace(/[\xF2-\xF6\xF8]/g, 'o')
      .replace(/[\xF9-\xFC]/g, 'u')
      .replace(/[\xFE]/g, 'p')
      .replace(/[\xFD\xFF]/g, 'y');
  }
  return stringFormatted;
};

const removeNonWord = string => {
  return string.replace(/[^0-9a-zA-Z\xC0-\xFF \-]/g, '');
};

const stringToCamelCase = string => {
  let stringFormatted = string;
  stringFormatted = replaceAccents(stringFormatted);
  stringFormatted = removeNonWord(stringFormatted)
    .replace(/\-/g, ' ')
    .replace(/\s[a-z]/g, upperCase)
    .replace(/\s+/g, '')
    .replace(/^[A-Z]/g, lowerCase);
  return stringFormatted;
};

const stringToUndoCamelCase = string => {
  let stringFormatted = string;
  stringFormatted = stringFormatted.replace(
    /([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g,
    '$1 $2'
  );
  stringFormatted = stringFormatted.toLowerCase();
  return stringFormatted;
};

const stringNormalize = string => {
  return isString(string)
    ? string
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
    : '';
};

const isSourceContainsSearch = (source, search) => {
  const sourceNormalized = stringNormalize(source);
  const searchNormalized = stringNormalize(search);
  return sourceNormalized.includes(searchNormalized);
};

const stringFromCamelCaseToKebabCase = string => {
  const stringFormatted = string
    .replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2')
    .toLowerCase();
  return stringFormatted;
};

const stringFromCamelCaseToCssVar = string => {
  const kebabCaseString = stringFromCamelCaseToKebabCase(string);
  const cssVarString = `--${kebabCaseString}`;
  return cssVarString;
};

const stringFromCamelCaseToLessVar = string => {
  const kebabCaseString = stringFromCamelCaseToKebabCase(string);
  const cssVarString = `@${kebabCaseString}`;
  return cssVarString;
};

const getWidthTextElement = textElement => {
  const element = textElement.lastElementChild || textElement;
  element.style.width = 'fit-content';
  const width = element.offsetWidth;
  element.style.width = null;

  return width;
};

export {
  stringToCamelCase,
  stringToUndoCamelCase,
  stringNormalize,
  isSourceContainsSearch,
  stringFromCamelCaseToKebabCase,
  stringFromCamelCaseToCssVar,
  stringFromCamelCaseToLessVar,
  getWidthTextElement,
  capitalizeFirstLetter
};
