import React from 'react';
import PropTypes from 'prop-types';
import { Button as ButtonAntd } from 'antd';

const Button = ({
  children,
  type,
  className,
  size,
  htmlType,
  onClick,
  isLoading,
  disabled,
  cleanButton,
  danger,
  fullWidth,
  href,
  target
}) => {
  return cleanButton ? (
    <button onClick={onClick} type="button" className="clean-button">
      {children}
    </button>
  ) : (
    <ButtonAntd
      className={`button ${className}`}
      type={type}
      size={size}
      htmlType={htmlType}
      onClick={onClick}
      loading={isLoading}
      disabled={disabled}
      danger={danger}
      block={fullWidth}
      href={href}
      target={target}
    >
      {children}
    </ButtonAntd>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  htmlType: PropTypes.string,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  cleanButton: PropTypes.bool,
  danger: PropTypes.bool,
  fullWidth: PropTypes.bool,
  href: PropTypes.string,
  target: PropTypes.string
};
Button.defaultProps = {
  className: '',
  type: 'default',
  size: 'middle',
  htmlType: 'button',
  onClick: undefined,
  isLoading: false,
  disabled: false,
  cleanButton: false,
  danger: false,
  fullWidth: false,
  href: undefined,
  target: undefined
};

export default Button;
