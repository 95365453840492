const isTypeNumber = number => {
  return typeof number === 'number';
};

const isNumber = number => {
  return typeof number === 'number' && Number.isFinite(number);
};

const areSameNumbers = (number, number2) => {
  return isNumber(number) && isNumber(number2) && number === number2;
};

export { isTypeNumber, isNumber, areSameNumbers };
