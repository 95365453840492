import { useMemo } from 'react';
import PropTypes from 'prop-types';

const Account = ({ color, size }) => {
  const iconStyles = useMemo(() => {
    if (!color) {
      return null;
    }
    return { fill: color };
  }, [color]);
  return (
    <svg
      className={`icon account-icon ${size}`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={iconStyles}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3H5C3.89 3 3 3.9 3 5ZM15 9C15 10.66 13.66 12 12 12C10.34 12 9 10.66 9 9C9 7.34 10.34 6 12 6C13.66 6 15 7.34 15 9ZM12 13.9C10 13.9 6 15 6 17V18H18V17C18 15 14 13.9 12 13.9Z"
      />
    </svg>
  );
};
Account.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string
};
Account.defaultProps = {
  color: undefined,
  size: ''
};

export default Account;
