import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import inputEmptyText from '../utils/constants/inputEmptyText';
import { isStringWithCharacters } from '../../../../../../utils/functions/validator/stringValidator';
import { isNumber } from '../../../../../../utils/functions/validator/numberValidator';
import { isObject } from '../../../../../../utils/functions/validator/objectValidator';

const InputEmptyValue = ({ text, inputType }) => {
  const [emptyText, setEmptyText] = useState(undefined);

  useEffect(() => {
    const handleDefaultEmptyText = () => {
      const canAddPropText =
        isStringWithCharacters(text) || isNumber(text) || isObject(text);
      if (canAddPropText) {
        setEmptyText(text);
        return;
      }
      switch (inputType) {
        case 'select':
          setEmptyText(inputEmptyText.select);
          break;
        case 'datepicker':
          setEmptyText(inputEmptyText.datepicker);
          break;
        case 'input':
        default:
          setEmptyText(inputEmptyText.default);
          break;
      }
    };
    handleDefaultEmptyText();
  }, [inputType, text]);

  return <span className="input-info__value-empty">{emptyText}</span>;
};

InputEmptyValue.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node
  ]),
  inputType: PropTypes.string
};
InputEmptyValue.defaultProps = {
  text: undefined,
  inputType: undefined
};

export default InputEmptyValue;
