import { useMemo } from 'react';
import PropTypes from 'prop-types';

const Sellers = ({ color, size }) => {
  const iconStyles = useMemo(() => {
    if (!color) {
      return null;
    }
    return { fill: color };
  }, [color]);
  return (
    <svg
      className={`icon sellers-icon ${size}`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={iconStyles}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 9C15 7.34 13.66 6 12 6C10.34 6 9 7.34 9 9C9 10.66 10.34 12 12 12C13.66 12 15 10.66 15 9ZM6 11C6 12.1 5.1 13 4 13C2.9 13 2 12.1 2 11C2 9.9 2.9 9 4 9C5.1 9 6 9.9 6 11ZM16.24 13.65C15.07 13.14 13.63 12.75 12 12.75C10.37 12.75 8.93 13.14 7.76 13.66C6.68 14.13 6 15.21 6 16.39V18H18V16.38C18 15.21 17.32 14.13 16.24 13.65ZM5.13 14.1C4.76 14.04 4.39 14 4 14C3.01 14 2.07 14.21 1.22 14.58C0.48 14.9 0 15.62 0 16.43V18H4.5V16.39C4.5 15.56 4.73 14.78 5.13 14.1ZM22 11C22 12.1 21.1 13 20 13C18.9 13 18 12.1 18 11C18 9.9 18.9 9 20 9C21.1 9 22 9.9 22 11ZM24 16.43C24 15.62 23.52 14.9 22.78 14.58C21.93 14.21 20.99 14 20 14C19.61 14 19.24 14.04 18.87 14.1C19.27 14.78 19.5 15.56 19.5 16.39V18H24V16.43Z"
      />
    </svg>
  );
};
Sellers.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string
};
Sellers.defaultProps = {
  color: undefined,
  size: ''
};

export default Sellers;
