import { notification as notificationAntd } from 'antd';

const notification = props => {
  const { type, message, ...restProps } = props;
  const config = {
    placement: 'topRight'
  };

  notificationAntd.config(config);
  switch (type) {
    case 'success':
      notificationAntd.success({ ...restProps, message });
      break;
    case 'error':
      notificationAntd.error({ ...restProps, message });
      break;
    case 'info':
      notificationAntd.info({ ...restProps, message });
      break;
    case 'warning':
      notificationAntd.warning({ ...restProps, message });
      break;
    default:
      notificationAntd.open({ ...restProps, message });
      break;
  }
  return notificationAntd;
};

export default notification;
