import { useMemo } from 'react';
import PropTypes from 'prop-types';

const Establishments = ({ color, size }) => {
  const iconStyles = useMemo(() => {
    if (!color) {
      return null;
    }
    return { fill: color };
  }, [color]);
  return (
    <svg
      className={`icon establishments-icon ${size}`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={iconStyles}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 3H17V11H21V21H13V17H11V21H3V7H7V3ZM5 19H7V17H5V19ZM7 15H5V13H7V15ZM5 11H7V9H5V11ZM11 15H9V13H11V15ZM9 11H11V9H9V11ZM11 7H9V5H11V7ZM13 15H15V13H13V15ZM15 11H13V9H15V11ZM13 7H15V5H13V7ZM19 19H17V17H19V19ZM17 15H19V13H17V15Z"
      />
    </svg>
  );
};
Establishments.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string
};
Establishments.defaultProps = {
  color: undefined,
  size: ''
};

export default Establishments;
