import PropTypes from 'prop-types';
import HeadNext from 'next/head';
import { capitalizeFirstLetter } from '../../../utils/functions/formatter/stringFormatter';

const Head = ({ title, description, favicon }) => {
  return (
    <HeadNext>
      {title && <title>{`${capitalizeFirstLetter(title)} | DeepUpSell`}</title>}
      {description && <meta name="description" content={description} />}
      {favicon && <link rel="icon" href={favicon} />}
    </HeadNext>
  );
};

Head.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  favicon: PropTypes.string
};
Head.defaultProps = {
  title: undefined,
  description: undefined,
  favicon: undefined
};

export default Head;
